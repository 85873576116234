import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./resources.json";

export default () => {
  i18n.use(initReactI18next).init({
    debug: process.env.NODE_ENV === "development",
    initImmediate: false,
    preload: ["en", "nl"],
    fallbackLng: "en",
    lng: "en",
    resources,
    keySeparator: ".",
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: { useSuspense: false }
  });

  return i18n;
};
