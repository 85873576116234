import React from "react";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <footer className="bg-jungle-400 text-white p-16 text-center flex flex-col items-center">
        <p className="mb-4 max-w-3xl">{t("footer.anbi")}</p>
        <p className="mb-4">
          <a
            href="https://drive.google.com/drive/folders/1dDz_vNqQwzIyo7wqMSg-5WSc9QsmgJGq?usp=sharing"
            target="_blank"
            className="text-white"
          >
            {t("footer.link_reports")}
          </a>
        </p>
        <p>
          {t("footer.label_contact")}{" "}
          <a href="mailto:info@wildlifewatchdogs.com" className="text-white">
            {t("footer.link_contact")}
          </a>
          !
        </p>
      </footer>
    </>
  );
}

export default Footer;
