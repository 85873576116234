import React from "react";
import { useTranslation } from "react-i18next";

const Partners = () => {
  const { t } = useTranslation();
  return (
    <>
      <hr className="my-12"></hr>
      <h2 className="c-h2">Onze partners</h2>
      <ul className="my-6">
        <li className="c-p">
          <h2 className="c-h4 mb-0">Cikananga Wildlife Center</h2>
          <a href="http://www.cikanangawildlifecenter.com" target="_blank">
            cikanangawildlifecenter.com/
          </a>
        </li>
        <li className="c-p">
          <h2 className="c-h4 mb-0">
            Sumatran Orangutan Conservation Programme
          </h2>
          <a href="https://www.sumatranorangutan.org" target="_blank">
            sumatranorangutan.org
          </a>
        </li>
        <li className="c-p">
          <h2 className="c-h4 mb-0">The Dolphin Project</h2>
          <a href="https://www.dolphinproject.com/" target="_blank">
            dolphinproject.com
          </a>
        </li>
        <li className="c-p">
          <h2 className="c-h4 mb-0">Animal Friends Jogja</h2>
          <a href="https://www.animalfriendsjogja.org/" target="_blank">
            animalfriendsjogja.org
          </a>
        </li>
        <li className="c-p">
          <h2 className="c-h4 mb-0">Borneo Orangutan Survival</h2>
          <a href="https://orangutan.or.id/" target="_blank">
            orangutan.or.id
          </a>
        </li>
      </ul>
    </>
  );
};

export default Partners;
