import React from "react";
import PropTypes from "prop-types";

import { Link } from "gatsby";
import LocalizedLink from "./localizedLink";
import { useTranslation } from "react-i18next";

import wildlifeWatchdogs from "../images/wildlife-new.svg";

function Header({ siteTitle, slug }) {
  const { t } = useTranslation();
  const navClass = "py-3 block text-white no-underline hover:text-jungle-200";
  const activeClass = "text-jungle-200";

  return (
    <header className="bg-jungle-400 bg-edge bg-edge--be-400 pt-2">
      <div className="block lg:flex w-full max-w-5xl mx-4 mx-auto items-center justify-between">
        <div className="flex justify-between items-center">
          <LocalizedLink to="/" className="block w-32">
            <img
              src={wildlifeWatchdogs}
              alt={siteTitle}
              className="w-32 lg:w-40 mx-auto my-2 mt-4 mb-1"
            />
          </LocalizedLink>
          <label
            htmlFor="menuToggle"
            className="text-white lg:hidden m-4 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              className="w-6"
            >
              <path
                fill="#fff"
                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
              />
            </svg>
          </label>
        </div>

        <input type="checkbox" id="menuToggle" className="hidden" />

        <nav className="hidden lg:block">
          <ul className="lg:flex items-center justify-between">
            <li className="mx-4">
              <LocalizedLink
                className={navClass}
                activeClassName={activeClass}
                to="/"
              >
                {t("navigation.home")}
              </LocalizedLink>
            </li>
            <li className="mx-4">
              <LocalizedLink
                className={navClass}
                activeClassName={activeClass}
                to="/projects/sumatra-wildlife-center"
              >
                {t("navigation.projects")}
              </LocalizedLink>
            </li>
            <li className="mx-4">
              <LocalizedLink
                className={navClass}
                activeClassName={activeClass}
                to="/animals/dance-monkeys"
              >
                {t("navigation.animals")}
              </LocalizedLink>
            </li>
            <li className="mx-4">
              <LocalizedLink
                className={navClass}
                activeClassName={activeClass}
                to="/volunteers"
              >
                {t("navigation.volunteers")}
              </LocalizedLink>
            </li>
            <li className="mx-4">
              <LocalizedLink
                className={navClass}
                activeClassName={activeClass}
                to="/about"
              >
                {t("navigation.about")}
              </LocalizedLink>
            </li>
            <li className="mx-4">
              <LocalizedLink
                className={navClass}
                activeClassName={activeClass}
                to="/news"
              >
                {t("navigation.news")}
              </LocalizedLink>
            </li>
          </ul>
        </nav>

        <div className="mx-4 text-white">
          <Link
            to={`${slug ? slug : ""}`}
            hrefLang="en"
            className="text-white no-underline"
          >
            EN
          </Link>
          <span className="mx-1 opacity-50">/</span>
          <Link
            to={`/nl${slug}`}
            hrefLang="nl"
            className="text-white no-underline"
          >
            NL
          </Link>
        </div>

        <LocalizedLink
          className="c-button mx-4 my-4 lg:my-0 lg:mx-0 c-button--white"
          to="/donate"
        >
          {t("btn.donate")}
        </LocalizedLink>
      </div>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
