import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import withI18next from "../i18n/withI18next";
import Wrapper from "../components/wrapper";

const Layout = ({ children, location, pageContext: { locale, slug } }) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [location, i18n, locale]);

  return (
    <>
      <Wrapper slug={slug}>{children}</Wrapper>
    </>
  );
};

export default withI18next()(Layout);
