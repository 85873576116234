import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";

const CookiesModal = () => {
  const [active, setActive] = useState(null);
  const gdprCookie = "gatsby-gdpr-google-analytics";

  const handleClick = event => {
    event.preventDefault();
    setActive(true);
    Cookies.set(gdprCookie, true, { expires: 365 });
  };

  useEffect(() => {
    (async () => {
      const ready = await document;
      if (ready && Cookies.get(gdprCookie)) {
        setActive(true);
      }
    })();
  }, []);

  return (
    <>
      <div
        className={`${
          active ? "hidden" : "block"
        } bg-jungle-400 text-white p-3 px-5 rounded-t max-w-lg fixed abs-center bottom-0 shadow-lg whitespace-no-wrap`}
      >
        <span className="mr-3 inline-block">We'd like to set cookies</span>
        <a
          href="#"
          className="c-button c-button--small c-button--white"
          onClick={handleClick}
        >
          I accept
        </a>
      </div>
    </>
  );
};

export default CookiesModal;
