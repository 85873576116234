import React from "react";
import { useTranslation } from "react-i18next";

const People = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="md:grid">
        <div className="col-span-6">
          <h3 className="c-h3">Medical Team</h3>
          <ul className="pl-4 border-l-2 border-jungle-100">
            <li>
              Mariana Ferdinandez
              <em className="text-gray-500 ml-2">Wildlife Veterinarian</em>
            </li>
            <li>
              Dhr Deny Fajar
              <em className="text-gray-500 ml-2">Wildlife veterinarian</em>
            </li>
          </ul>
        </div>
        <div className="col-span-6">
          <h3 className="c-h3">Program Pulau Seribu</h3>
          <ul className="pl-4 border-l-2 border-jungle-100">
            <li>
              Devi
              <em className="text-gray-500 ml-2">
                Coordinator and manager, Biologist
              </em>
            </li>
            <li>
              Sabar<em className="text-gray-500 ml-2">Head of animal care</em>
            </li>
            <li>
              Angga<em className="text-gray-500 ml-2">Caretaker</em>
            </li>
            <li>
              Hamzah<em className="text-gray-500 ml-2">Caretaker</em>
            </li>
          </ul>
        </div>
        <div className="col-span-6">
          <h3 className="c-h3">Karimun Jawa Dolphin Project</h3>
          <ul className="pl-4 border-l-2 border-jungle-100">
            <li>
              Elly<em className="text-gray-500 ml-2">Manager</em>
            </li>
            <li>
              Sus<em className="text-gray-500 ml-2">Field coordinator</em>
            </li>
            <li>
              Amank<em className="text-gray-500 ml-2">Field coordinator</em>
            </li>
          </ul>
        </div>
        <div className="col-span-6">
          <h3 className="c-h3">Lembata SeaTurtle Project</h3>
          <ul className="pl-4 border-l-2 border-jungle-100">
            <li>
              Bala<em className="text-gray-500 ml-2">Manager</em>
            </li>
            <li>
              Aldo<em className="text-gray-500 ml-2">Field coordinator</em>
            </li>
            <li>
              Cor<em className="text-gray-500 ml-2">Field staff</em>
            </li>
            <li>
              Jos<em className="text-gray-500 ml-2">Field Staff</em>
            </li>
          </ul>
        </div>
        <div className="col-span-6">
          <h3 className="c-h3">Sumatra Wildlife Project</h3>
          <ul className="pl-4 border-l-2 border-jungle-100">
            <li>
              Anita<em className="text-gray-500 ml-2">Manager</em>
            </li>
            <li>
              Alek
              <em className="text-gray-500 ml-2">
                Biologist, Animal Rehabilitation
              </em>
            </li>
            <li>
              Glenn
              <em className="text-gray-500 ml-2">
                K9 Unit detection dogs Coordinator
              </em>
            </li>
            <li>
              Igin
              <em className="text-gray-500 ml-2">
                K9 Unit detection dogs team
              </em>
            </li>
            <li>
              Ambon
              <em className="text-gray-500 ml-2">
                K9 Unit detection dogs team
              </em>
            </li>
            <li>
              Hengky<em className="text-gray-500 ml-2">Security</em>
            </li>
            <li>
              RT<em className="text-gray-500 ml-2">Security</em>
            </li>
            <li>
              Izzy<em className="text-gray-500 ml-2">Builder</em>
            </li>
          </ul>
        </div>
        <div className="col-span-6">
          <h3 className="c-h3">Jakarta Office</h3>
          <ul className="pl-4 border-l-2 border-jungle-100">
            <li>Maya</li>
            <li>
              Herly<em className="text-gray-500 ml-2">Finances</em>
            </li>
            <li>
              Adi Ambon<em className="text-gray-500 ml-2">Finances</em>
            </li>
            <li>
              Darmadi<em className="text-gray-500 ml-2">Flying Resque Team</em>
            </li>
          </ul>
        </div>
        <div className="col-span-6">
          <h3 className="c-h3">Board of the Netherlands</h3>
          <ul className="pl-4 border-l-2 border-jungle-100">
            <li>
              Justin Bouwens<em className="text-gray-500 ml-2">Chairman</em>
            </li>
            <li>
              Elisa Brongers
              <em className="text-gray-500 ml-2">General board member</em>
            </li>
            <li>
              Leonoor Baaten<em className="text-gray-500 ml-2">Secretariat</em>
            </li>
            <li>
              Mark van der Pal
              <em className="text-gray-500 ml-2">General board member</em>
            </li>
            <li>
              Jacques Bosboom
              <em className="text-gray-500 ml-2">Treasurer</em>
            </li>
            <li>
              Dennis Weening<em className="text-gray-500 ml-2">Ambassador</em>
            </li>
          </ul>
        </div>
        <div className="col-span-6">
          <h3 className="c-h3">Website &amp; Content</h3>
          <ul className="pl-4 border-l-2 border-jungle-100">
            <li>
              Wes Oudshoorn
              <em className="text-gray-500 ml-2">Design &amp; building</em>
            </li>
            <li>
              Anton Kouliavtsev
              <em className="text-gray-500 ml-2">Bouw &amp; donation flow</em>
            </li>
            <li>
              Djenna Perreijn
              <em className="text-gray-500 ml-2">Content &amp; editing</em>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default People;
